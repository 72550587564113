<template lang="pug">
	.historico-visualizar
		Dialog.dialogVisualizar(:modal="true" header= "Informações da conta" :visible.sync="show")
			.p-grid.p-fluid
				.p-col-12(style="align-self: flex-end; max-width: 720px;")
					div.mb-4(v-if='this.model.boleto_url')
						.mt-2.ta-right(v-if='model')
							.ml-2(style='display:inline-block')
								Button.p-button-success(label='Imprimir boleto' icon="jam jam-printer" @click="abrirBoleto()")
					.__box-imprimir(v-if='model' style='margin: 0 auto; max-width: 720px; align-self: flex-end;')
						fieldset
							legend.text-title Resumo
							.p-grid.p-align-end.mb-3.mt-2.ml-1
								.p-col-6
									p <b>Empresa:</b> {{ model.ds_credor }}
									p(v-if='model.ie_tipo_titulo === "TA"') <b>Valor:</b> 15% de {{ formatPrice(model.vl_aporte) }} (aporte) = <b> {{ formatPrice(model.vl_titulo) }} </b>
									p(v-else) <b> Valor: {{ formatPrice(model.vl_titulo) }} </b>
									p <b>Data de Vencimento:</b> {{ model.dt_vencimento_f }}
								.p-col-6
									p <b>Situação da conta:</b> {{ model.ie_situacao_f }}
									p <b>Tipo de conta:</b> {{ model.ie_tipo_titulo_f }}
									p <b>Parcela atual:</b> {{ model.nr_parcela_atual }} / {{ model.qt_total_parcela }}
	
						fieldset(v-if="model.ds_utilizacao?.length")
							legend.text-title Correntistas
							DataTable.datatable-list(:value='model.ds_utilizacao')
								Column(headerStyle='width: 60%;' bodyStyle='text-align: center' field='nm_correntista' header='Correntista' sortable)
								Column(headerStyle='width: 20%;' bodyStyle='text-align: center' field='ie_tipo' header='Tipo' sortable)
									template(#body="{data}")
										span {{ data.ie_tipo === "D" ? "Dependente" : "Titular" }}
								Column(headerStyle='width: 20%;' bodyStyle='text-align: right' header='Valor' field='nr_valor' sortable)
									template(#body='props')
										span {{ formatPrice(props.data.nr_valor) }}

</template>

<script>
	import Dialog from 'primevue/dialog';
	import ProgressBar from 'primevue/progressbar'
	import Panel from 'primevue/panel'
	import InputText from 'primevue/inputtext'
	import InputMask from 'primevue/inputmask'
	import Password from 'primevue/password'
	import Button from 'primevue/button'
	import Dropdown from 'primevue/dropdown'
	import SelectButton from 'primevue/selectbutton'
	import Calendar from 'primevue/calendar'
	import DataTable from 'primevue/datatable'
	import Column from 'primevue/column'
	import ColumnGroup from 'primevue/columngroup'
	import Row from 'primevue/row'

	export default {
		components: {
			ProgressBar, Panel, InputText, Button, Password, Dialog, InputMask, SelectButton, Dropdown, Calendar, DataTable, Column,
			ColumnGroup, Row,
		},
		props: [
			'display', 'model',
		],
		computed: {
			show: {
				get() { 
					return this.display; 
				},
				set(value) { if (!value) this.$emit('close'); }
			},
		},
		methods: {
			abrirBoleto() {
                if (this.model.boleto_url)
                    window.open(this.model.boleto_url);
                else this.$toast.error("Link para o arquivo não encontrado.");
            },
			formatPrice (val) {
				return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
			},
		}
	}
</script>

<style lang="scss">
	.dialogVisualizar {
		width: 98%;
		max-width: 750px;
	}
	.historico-visualizar {
		.spinner {
			margin: 100px 0;
		}
		.text-footer {
			font-size: 12px;
			margin-top: -10px !important;
		}
		.img-logo {
			max-width: 160px;
			@media screen {
				display: none;
			}
		}
		.__box-imprimir {
			border: 1px solid #c8c8c8;
			padding: 20px;
			fieldset {
				border: 1px solid #c8c8c8;
			}
			p {
				margin: 2px 0;
				font-size: 12px;
				color: #333;
			}
			.text-title {
				color: #657786;
				font-size: 14px;
				font-weight: 700;
			}
			.table {
				border-bottom: 1px solid #c8c8c8;
				.row {
					font-size: 0;
					border-top: 1px solid #c8c8c8;
					border-left: 1px solid #c8c8c8;
					.col {
						display: inline-block;
						vertical-align: middle;
						font-size: 12px;
						border-right: 1px solid #c8c8c8;
						padding: 2px 6px;
					}
				}
			}
			.p-progress-spinner {
				height: 29px;
				width: 29px;
			}
			@media print {
				.box-hide { display: none }
			}
			.datatable-list {
				.p-column-title, td {
					font-size: 12px;
				}
			}
			.highlight {
				color: #007ad9;
				font-weight: 700;
			}
		}
	}
</style>