<template lang="pug">
.main-wrapper.caixas-listar
    header.main-wrapper-header
        .p-grid.p-align-center
            .p-col-12.ta-right
                h1.text-header.text-secondary MedClub / <b>Contas a Pagar</b>
    
    DialogVisualizar(v-if='dialogVisualizar' :display='dialogVisualizar' :model='dialogVisualizar_data' @close='dialogVisualizar = false; dialogVisualizar_data = null')

    DialogAnexos(
        v-if='dialogAnexos'
        :display='dialogAnexos'
        :anexos='dialogAnexos_data'
        @close='dialogAnexos = false'
    )
    
    Panel.my-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576' mode="indeterminate")
        .p-grid.p-fluid.p-align-end
            .p-col-12.p-md-3
                label.form-label Situação:
                ProgressBar(v-if='loading.situacao' mode="indeterminate")
                .p-inputgroup(v-else)
                    Dropdown(
                        placeholder='Selecione situação...'
                        :options='options.situacao'
                        optionLabel='text'
                        optionValue='value'
                        @change='applyFilters()'
                        v-model='filters.ie_situacao'
                        filter
                    )
            .p-col-12.p-md-3
                label.form-label Data Inicial:
                .p-inputgroup
                    Calendar( v-model='filters.dt_inicial' dateFormat="dd/mm/yy" :locale="ptbr"
                        :manualInput='false' :touchUI='windowInnerWidth < 576' @input='applyFilters()' )
                    Button(icon='jam jam-rubber' @click="() => {filters.dt_inicial = null; applyFilters();}")

            .p-col-12.p-md-3
                label.form-label Data Final:
                .p-inputgroup
                    Calendar( v-model='filters.dt_final' dateFormat="dd/mm/yy" :locale="ptbr"
                        :manualInput='false' :touchUI='windowInnerWidth < 576' @input='applyFilters()' )
                    Button(icon='jam jam-rubber' @click="() => {filters.dt_final = null; applyFilters();}")

            .p-col-3.p-md-3.ta-right
                Button(label='Limpar filtros' icon='jam jam-rubber' @click='limparFiltros()')
    .p-grid
        .p-col-9.p-grid.my-2.p-fluid
            .info-container
                span(style='color: #F42C2C; padding: 5px; font-size: 12px') Contas Vencidas: {{ formatPrice(info.contas_vencidas) }}
            .info-container
                span(style='color: #E07D09 ; padding: 5px; font-size: 12px') Contas a vencer: {{ formatPrice(info.contas_vencer) }}
            .info-container
                span(style='color: #48BA53 ; padding: 5p; font-size: 12px') Contas Pagas: {{ formatPrice(info.contas_pagas) }}
            .info-container
                span(style='padding: 5p; font-size: 12px') Contas Total: {{ formatPrice(info.total) }}

    ProgressBar(v-if='waiting' mode="indeterminate")
    div(v-else-if='list.length == 0')
        p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
    div(v-else)
        DataView.dataview.my-2(:value="list" layout="grid")
            template(#grid="props")
                .p-col-12.p-md-3(style="padding: .5em" :class="{ disabled: !props.data.ie_status }")
                    Panel.panel-list.ta-center(:header="props.data.nm_centro_custo" style='position: relative')
                        .ta-left
                            p <b>Data de Vencimento:</b> {{ props.data.dt_vencimento_f }}
                        .ta-left
                            p <b>Data de Pagamento:</b> {{ props.data.dt_liquidacao_f }}
                        .ta-left
                            p <b>Descrição:</b> {{ props.data.ds_descricao }}
                        .ta-left
                            p <b>Valor:</b> {{formatPrice(props.data.vl_titulo)}}
                        .ta-left
                            p <b>Situação:</b> {{ options.situacao.find(item => (item.value === props.data.ie_situacao) && (props.data.ie_situacao !== null))?.text }}
                        .ta-right
                            Button.p-button-success.p-button-raised.p-button-rounded.mr-1(
                                v-if="props.data.boleto_url"
                                v-tooltip.top="'Boleto'"
                                icon="jam jam-printer"
                                @click="abrirBoleto(props.data)"
                            )
                            Button.p-button-success.p-button-raised.p-button-rounded.mr-1(
                                v-tooltip.top="'Anexar comprovante'"
                                icon="jam jam-attachment"
                                @click="dialogAnexos_data = props.data; dialogAnexos = true"
                            )
                            Button.p-button-raised.p-button-rounded.mr-1(
                                v-tooltip.top="'Visualizar'"
                                icon="pi pi-external-link"
                                @click="dialogVisualizar_data = props.data; dialogVisualizar = true;"
                            )

        Paginator.dataview(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

        Panel.datatable(header='Lista de Contas a Pagar')
            DataTable(:value="list")
                Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' field='dt_vencimento_f' header='Data de Vencimento')
                Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' field='dt_liquidacao_f' header='Data de Pagamento')
                Column(headerStyle='width: 15%;' bodyStyle='text-align: center;' field='ds_descricao' header='Descrição')
                Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' field='nr_valor' header='Valor')
                    template(#body='props')
                        span {{formatPrice(props.data.vl_titulo)}}
                Column(headerStyle='width: 15%;' bodyStyle='padding: 0; text-align: center;' field='situacao' header='Situação')
                    template(#body='props')
                        .cell(:style="{'background-color': options.situacao.find(item => item.value === props.data.ie_situacao)?.color}")
                            span {{ options.situacao.find(item => (item.value === props.data.ie_situacao) && (props.data.ie_situacao !== null))?.text }} <br />
                Column(headerStyle='width: 10%; text-align: center')
                    template(#header) Ações
                    template(#body='props')
                        .ta-center
                            Button.p-button-success.p-button-raised.p-button-rounded.mr-1(
                                v-if="props.data.boleto_url"
                                v-tooltip.top="'Boleto'"
                                icon="jam jam-printer"
                                @click="abrirBoleto(props.data)"
                            )
                            Button.p-button-success.p-button-raised.p-button-rounded.mr-1(
                                v-tooltip.top="'Anexar comprovante'"
                                icon="jam jam-attachment"
                                @click="dialogAnexos_data = props.data; dialogAnexos = true"
                            )
                            Button.p-button-raised.p-button-rounded.mr-1(
                                v-tooltip.top="'Visualizar'"
                                icon="pi pi-external-link"
                                @click="dialogVisualizar_data = props.data; dialogVisualizar = true;"
                            )
            Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
</template>
    
<style lang="scss">
    .caixas-listar {
        .dataview {
            @media all and (min-width: 577px) {
                display: none;
            }
        }
        .info-container {
            border: 1px solid #CACACA;
            border-radius: 5px;
            padding: 3px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 600;
            margin-right: 10px;
        }
        .datatable {
            @media all and (max-width: 576px) {
                display: none;
            }
            .cell {
                padding: 16px 0;
                justify-content: center;
                align-items: center;
                text-align: center;
            }
        }
        .dialogApagar {
            .p-progress-spinner {
                width: 29px;
                height: auto;
            }
        }
    }
    .p-tooltip {
        max-width: none;
    }
</style>
    
<script>
    import ProgressBar from 'primevue/progressbar'
    import DataView from 'primevue/dataview'
    import Panel from 'primevue/panel'
    import Paginator from 'primevue/paginator'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import Button from 'primevue/button'
    import Tooltip from 'primevue/tooltip'
    import Dialog from 'primevue/dialog'
    import InputText from 'primevue/inputtext'
    import InputNumber from 'primevue/inputnumber'
    import Dropdown from 'primevue/dropdown'
    import ProgressSpinner from 'primevue/progressspinner'
    import Calendar from 'primevue/calendar'
    import SelectButton from 'primevue/selectbutton'
    import moment from 'moment'
    import DialogVisualizar from './DialogVisualizar.vue'
    import DialogAnexos from './DialogAnexos.vue'
    
    import { pCalendarLocale_ptbr, processDominiosResponse } from './../../utils'
    import { Empresa, DominioValor } from './../../middleware'
    import wsConfigs from './../../middleware/configs'
    export default {
        created () {
            Promise.all([
                this.getDominios(),
            ]).then(() => {
                this.applyFilters();
        });
        },
        watch: {
            "dialogVisualizar": function (val) {
                if(val) {
                    this.dialogVisualizar_data.dt_vencimento_f = moment(this.dialogVisualizar_data.dt_vencimento).format('DD/MM/YYYY')
                    this.dialogVisualizar_data.ie_tipo_titulo_f = this.options.tipo.find(item => (item.value === this.dialogVisualizar_data.ie_tipo_titulo))?.text
                    this.dialogVisualizar_data.ie_situacao_f = this.options.situacao.find(item => (item.value === this.dialogVisualizar_data.ie_situacao))?.text
                }
            }
        },
        components: { ProgressBar, DataView, Panel, Paginator, DataTable, InputNumber, SelectButton, DialogAnexos,
            Column, Button, Tooltip, Dialog, ProgressSpinner, InputText, Dropdown, Calendar, DialogVisualizar},
        directives: { tooltip: Tooltip },
        data () {
            return {
                list: [],
                windowInnerWidth: window.innerWidth,
                waiting: false,
                waitingApagar: false,
                waitingUnidades: false,
                dialogApagar: false,
                dialogApagar_data: {},
                dominios: null,
                dialogVisualizar: false,
                dialogVisualizar_data: null,
                dialogAnexos: false,
                dialogAnexos_data: null,
                ptbr: pCalendarLocale_ptbr,
                info: {
                    contas_pagas: 0,
                    contas_vencer: 0,
                    contas_vencidas: 0,
                    total: 0
                },
                filters: {
                    ie_situacao: null,
                    dt_inicial: null,
                    dt_final: null,
                },
                options:{
                    tipo: [],
					situacao: [],
                },
                paginator: {
                    page: this.$route.query.pg ? this.$route.query.pg : 1,
                    per_page: wsConfigs.paginator_perPage,
                    count: 0,
                },
                loading: {
					situacao: false,
				},
            }
        },
        methods: {
            abrirBoleto(params) {
                if (params.boleto_url)
                    window.open(params.boleto_url);
                else this.$toast.error("Link para o arquivo não encontrado.");
            },
            resetFilters() {
                this.filters.ie_situacao = null
				this.filters.dt_inicial = null
				this.filters.dt_final = null
			},
			limparFiltros() {
				this.resetFilters()
				this.applyFilters()
			},
            getList (params) {
                this.waiting = true
                return Empresa.getTitulosContratante(params).then(response => {
                    if (response.status == 200) {
                        this.paginator.count = response.data.data.count
                        this.info.contas_pagas = response.data.contas_pagas.total
                        this.info.contas_vencer = response.data.contas_vencer.total
                        this.info.contas_vencidas = response.data.contas_vencidas.total
                        this.info.total = response.data.total.total
                        response.data.data.results.forEach(item => {
                            item.dt_vencimento_f = item.dt_vencimento ? moment(item.dt_vencimento).format('DD/MM/YYYY') : ''
                            item.dt_liquidacao_f = item.dt_liquidacao ? moment(item.dt_liquidacao).format('DD/MM/YYYY') : ''
                        })
                        this.list = response.data.data.results
                        
                    }
                    this.waiting = false
                    return true
                })
            },
            getDominios () {
                this.loading.situacao = true
                DominioValor.findAll({ds_mnemonico: ['SITUACAO_CONTAS_RECEBER', 'TIPO_TITULO_RECEBER']}).then((response) => {
                    this.dominios = processDominiosResponse(response)
					if(response.status === 200) {
                        response.data['SITUACAO_CONTAS_RECEBER'].valores.forEach(item => {
							this.options.situacao.push({
                                text: item.ds_valor,
                                value: item.ie_valor,
                                color: item.ds_cor
                            })
                        })
                        response.data['TIPO_TITULO_RECEBER'].valores.forEach(item => {
							this.options.tipo.push({
                                text: item.ds_valor,
                                value: item.ie_valor,
                            })
                        })
                        this.loading.situacao = false
					}
				})
            },
            formatPrice (val) {
				return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
			},
            applyFilters () {
                let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }
                if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
                Object.keys(this.filters).forEach((key) => {
                    if ((this.filters[key])) {
                        if (key.substr(0, 3) == 'dt_')
                            params[key] = moment(this.filters[key]).utcOffset('-03:00').format('YYYY-MM-DD')
                        else params[key] = this.filters[key]
                    }
                })
                this.getList(params)
            },
            onPage (ev) {
                this.paginator.page = ev.page + 1
                this.applyFilters()
            },
        }
    }
</script>