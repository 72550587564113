<template>
    <Dialog :class="`dialog-confirm ${styleClass}`"
        class=""
        :modal="true"
        :showHeader="false"
        :visible.sync="show"
        :contentStyle="{overflowY: 'auto !important'}"

    >
        <div class="ta-center">
            <slot name="text-content">
                {{ text }}
            </slot>
        </div>
        <div class="ta-center mt-4">
<!--            <Button v-show="false" />-->
            <Button class="p-button-success mr-2" :label="positiveText" icon="pi pi-check" @click.once="onDialogConfirm" />
            <Button class="p-button-danger " :label="negativeText" icon="pi pi-times" @click="onDialogReject" />
        </div>
    </Dialog>
</template>

<script>
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';

export default {
    components: { Button, Dialog },
    props: {
        display: Boolean,
        text: String,
        positiveText: {
            type: String,
            default: 'Sim',
        },
        negativeText: {
            type: String,
            default: 'Não',
        },
        styleClass: {
            type: String,
            default: '',
        },
    },
    computed: {
        show: {
            get() { return this.display; },
            set(value) { if (!value) this.$emit('close'); }
        }
    },
    methods: {
        onDialogConfirm() {
            this.$emit('confirmed');
            this.show = false;
        },
        onDialogReject() {
            this.$emit('reject');
            this.show = false;
        },
    }
}
</script>

<style lang="scss">

.dialog-confirm {
    width: 300px;
    .p-dialog-content {
        padding-bottom: 6px;
    }
    .confirm-button {
        background-color: green;
    }
    .cancel-button {
        background-color: red;
    }
    .p-button.p-button-text {
        color: white;
        border-color: transparent;

        &:hover {
            background-color: transparent;
            color: black;
            border-color: black;
        }
    }
}
</style>
